export const RQ_REGISTER_PROJECT_REPORT_API = {
  url: 'api/v1/projectreport/register',
  method: 'POST',
  auth: true,
};

export const RQ_GET_PROJECT_REPORT_BY_ID_API = {
  url: 'api/v1/projectreport/<%= id %>/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_GET_PAGEABLE_ALL_PROJECT_REPORT_API = {
  url: 'api/v1/projectreport/pageable/lookup/search/all?page=<%= pageParam %>&',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_FIELDS_BY_PROJECT_REPORT_API = {
  url: 'api/v1/projectreport/<%= id %>/update/field',
  method: 'PUT',
  auth: true,
};

export const RQ_PUT_UPLOAD_PHOTO_FOR_PROJECT_REPORT_API = {
  url: 'api/v1/projectreport/<%= projectReportId %>/upload/outfallphoto',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_PROJECT_REPORT_PHOTO_FOR_ID_API = {
  url:'api/v1/projectreport/<%= id %>/outfallphoto/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_PUT_DELETE_PROJECT_REPORT_PHOTO_API = {
  url: 'api/v1/projectreport/<%= id %>/outfallphoto/<%= outfallphotoId %>/update/status',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_ALL_FIELDS_BY_PROJECT_REPORT_OUTFALLPHOTO_API = {
  url: 'api/v1/projectreport/<%= id %>/outfallphoto/<%= outfallphotoId %>/update/allfields',
  method: 'PUT',
  auth: true,
};

export const RQ_PUT_APPENDIX_FOR_PROJECT_REPORT_API = {
  url: 'api/v1/projectreport/<%= projectReportId %>/add/appendix',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_PROJECT_REPORT_APPENDIX_FOR_ID_API = {
  url:'api/v1/projectreport/<%= id %>/appendix/lookup',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_ALL_FIELDS_BY_PROJECT_REPORT_APPENDIX_API = {
  url:'api/v1/projectreport/<%= id %>/appendix/<%= appendixId %>/update/allfields',
  method: 'PUT',
  auth: true,
};

export const RQ_PUT_DELETE_PROJECT_REPORT_FOR_APPENDIX_API = {
  url:'api/v1/projectreport/<%= id %>/appendix/<%= appendixId %>/update/status',
  method: 'PUT',
  auth: true,
};
